import React from 'react';
import type { FC } from 'react';

import styled from 'styled-components';

import { tablet, useQuery } from 'styles/breakpoints';

import { Text } from 'components';
import IconWithCaption from '../IconWithCaption';
import Button from '../StartNowButton';

interface ListItem {
  img: string;
  title?: string;
  description?: string;
}

interface IconWithCaptionProps {
  data: {
    title?: string;
    overline?: string;
    list: ListItem[];
    startBtnTitle?: string;
    descriptionSmallerOnTablet?: boolean;
  };
  onClick: () => void;
  verticalFromTablet?: boolean;
  paddingY?: number;
  paddingX?: number;
}

const Container = styled.div<{ $paddingY?: number; $paddingX?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ $paddingY }) => $paddingY || 3}rem
    ${({ $paddingX }) => $paddingX || 1}rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 770px;
  gap: 3rem;
  @media ${tablet} {
    gap: 2rem;
  }
`;

const ListItems = styled.div<{ $verticalFromTablet?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 3rem;
  @media ${tablet} {
    align-items: ${({ $verticalFromTablet }) =>
      $verticalFromTablet ? 'center' : 'flex-start'};
    flex-direction: ${({ $verticalFromTablet }) =>
      $verticalFromTablet ? 'column' : 'row'};
    gap: ${({ $verticalFromTablet }) =>
      $verticalFromTablet ? '1.5rem' : '1rem'};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  max-width: 21.4375rem;
  width: 100%;
`;

const IconWithCaptionList: FC<IconWithCaptionProps> = ({
  data,
  verticalFromTablet = false,
  paddingY,
  paddingX,
  onClick,
}) => {
  const { isTablet } = useQuery();
  return (
    <Container $paddingY={paddingY} $paddingX={paddingX}>
      <ContentWrapper>
        {data?.title && (
          <Text type={isTablet ? 'h2' : 'h1'} textAlign="center">
            {data.title}
          </Text>
        )}
        <ListItems $verticalFromTablet={verticalFromTablet}>
          {data.list?.map((item, index) => (
            <IconWithCaption
              isVertical={isTablet && verticalFromTablet}
              descriptionSmallerOnTablet={data?.descriptionSmallerOnTablet}
              key={`${item.title}-${index}`}
              item={item}
            />
          ))}
        </ListItems>
        {data?.startBtnTitle && (
          <ButtonWrapper>
            <ButtonStyled onClick={onClick}>{data?.startBtnTitle}</ButtonStyled>
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};
export default React.memo(IconWithCaptionList);
