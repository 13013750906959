import React, { useCallback } from 'react';

import styled from 'styled-components';

import { DynamicImage, Text } from 'components';

import { tablet, useQuery } from 'styles/breakpoints';
import theme, { Typography } from 'utils/theme';

import TickIcon from 'assets/icons/tick-circle.svg';

interface IconWithCaptionProps {
  item: ListItem;
  width?: string;
  titleType?: Typography;
  isVertical?: boolean;
  descriptionSmallerOnTablet?: boolean;
  iconSize?: string;
}

interface ListItem {
  img: string;
  checkIcon?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  textAsIcon?: string;
}

const ItemContainer = styled.div<{ $width?: string; $isVertical?: boolean }>`
  display: flex;
  flex-direction: ${({ $isVertical }) => ($isVertical ? 'column' : 'column')};
  align-items: ${({ $isVertical }) => ($isVertical ? 'center' : 'center')};
  width: ${({ $width }) => $width || '240px'};
  gap: 0.57rem;
  @media ${tablet} {
    max-width: ${({ $width }) => $width || '300px'};
    width: 100%;
  }
`;

const ImageContainer = styled.div``;

const StyledDynamicImage = styled(DynamicImage)<{ $iconSize?: string }>`
  object-fit: contain;
  width: ${({ $iconSize }) => $iconSize || '4rem'};
  height: ${({ $iconSize }) => $iconSize || '4rem'};
`;

const TextStyled = styled(Text).attrs({
  color: 'dark80',
})`
  max-width: 100%;

  @media ${tablet} {
    max-width: 100%;
    width: 100%;
  }
`;
const StyledTextAsIcon = styled(Text).attrs({
  color: 'secondary',
  type: 'h125Rem',
})`
  // min-width: 100px;
  line-height: 100%;
`;

const TickIconStyled = styled(TickIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

const IconContainer = styled.div<{ $iconSize?: string; $isVertical?: boolean }>`
  width: ${({ $iconSize }) => $iconSize || '1.5rem'};
  height: ${({ $iconSize }) => $iconSize || '1.5rem'};
  margin-rigth: ${({ $isVertical }) => ($isVertical ? '0.75rem' : 0)};
`;

const IconWithCaption = ({
  item,
  width,
  titleType,
  isVertical,
  iconSize,
  descriptionSmallerOnTablet,
}: IconWithCaptionProps) => {
  const { isTablet } = useQuery();

  return (
    <ItemContainer $width={width} $isVertical={isVertical}>
      {item.checkIcon && (
        <IconContainer>
          <TickIconStyled
            fill={theme.colors.secondary}
            $iconSize={iconSize}
            $isVertical={isVertical}
          />
        </IconContainer>
      )}

      {item.img && (
        <ImageContainer>
          <StyledDynamicImage
            $iconSize={iconSize}
            src={item.img}
            alt={item?.title || item?.description || 'icon'}
          />
        </ImageContainer>
      )}

      {item?.textAsIcon && (
        <StyledTextAsIcon textAlign={isTablet ? 'center' : 'center'}>
          {item.textAsIcon}
        </StyledTextAsIcon>
      )}

      {item?.title && (
        <TextStyled
          type={titleType || 'h4'}
          textAlign={isVertical ? 'center' : 'center'}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
      )}
      {item?.subtitle && (
        <TextStyled
          type={isTablet ? 'bodyM500' : 'body500'}
          textAlign={isVertical ? 'center' : 'center'}
          dangerouslySetInnerHTML={{ __html: item.subtitle }}
        />
      )}

      {item?.description && (
        <TextStyled
          type={isTablet && descriptionSmallerOnTablet ? 'bodyM' : 'bodyM'}
          textAlign={isVertical ? 'center' : 'center'}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </ItemContainer>
  );
};

export default IconWithCaption;
